import app from './modules/app.js'

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// todo ssr
const store = new Vuex.Store({
	modules: {
		app
	}
})


export default store
