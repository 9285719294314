<template>
<div class="index-banner">
    <swiper class="swiper" :options="swiperOptionTop" ref="swiperTop">
        <swiper-slide v-for="(item,index) in banner" :key='index'>
            <!-- <img src="../assets/images/index_banner.jpg" alt=""> -->
            <img :src="item" alt="" class="banner-img">
        </swiper-slide>
    </swiper>
    
    <!-- <swiper class="swiper wjpaper-banner" :options="swiperOptionThumbs" ref="swiperThumbs">
        <swiper-slide v-for="(item,index) in banner" :key='index'>
            <div class="banner-item" :style="{backgroundImage:'url('+item+')'}">
                <div class="container">
                    <div class="banner-grid wow fadeInDown">
                        <div class="banner-lg">{{lang=='zh-cn'?slide.title:slide.title_en}}</div>
                        <div class="banner-desc">{{lang=='zh-cn'?slide.titile_comment:slide.titile_comment_en}}</div>
                        <div class="banner-foot">
                            <el-button type="cyan">头部主要按钮</el-button>
                            <el-button type="white">头部次要按钮</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </swiper-slide>
    </swiper> -->
</div>
</template>
<script>
export default {
    name: 'banner',
    props : {
        banner: { type : Array,  default : new Array([]) },
        slide : { type : Object, required: true }
    },
    data() {
        return {
            swiperOptionThumbs: {
                loop: true,
                autoplay: 4000,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper--next',
                    prevEl: '.swiper--prev'
                }
            },
            swiperOptionTop: {
                loop: true,
                autoplay: true,
                loopedSlides: 1,
            },
        }
    }
}
</script>